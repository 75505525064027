/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint simple-import-sort/imports: off */

import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';

import {
    StoreSwitcherContainer as SourceStoreSwitcherContainer,
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';

/** @namespace Scandipwa/Component/StoreSwitcher/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    currentStoreCode: state.ConfigReducer.code,
});

/** @namespace Scandipwa/Component/StoreSwitcher/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification(NotificationType.ERROR, message)),
});

/** @namespace Scandipwa/Component/StoreSwitcher/Container */
export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    componentDidUpdate(prevProps) {
        const { currentStoreCode } = this.props;
        const { prevStoreCode } = prevProps;
        const { storeLabel, storeList } = this.state;

        if (!storeList.length) {
            this._getStoreList();
        }

        if (currentStoreCode && (!storeLabel || (prevStoreCode !== currentStoreCode))) {
            this.getCurrentLabel(currentStoreCode);
        }
    }

    onStoreSwitcherClick() {
        const { isOpened } = this.state;

        this.setState({ isOpened: !isOpened });
    }

    onStoreSwitcherOutsideClick() {
        this.setState({ isOpened: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSwitcherContainer);
